import React from "react";
import Comping from "./../../images/Convo_2021.svg"

function UpComing() {
  return (
    <div className="max-w-7xl m-auto md:px-12 px-8 md:py-16 py-6">
      <h1 className="text-4xl font-medium md:mb-8 mb-4">Upcoming events</h1>
      <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-14 gap-8">
          <div>
              <div>
              <img src={Comping} alt="" />
              </div>
          </div>
          <div>
              <div className="pt-7">
              <p className="text-xs mb-6 font-normal tracking-widest">THURSDAY, 11 NOVEMBER, 1.00 PM SGT | 10.30 AM IST | 4.00 PM AEDT</p>
              <h2 className="font-medium text-3xl mb-5">APAC Convo Virtual 2021</h2>
              <h4 className="text-lg font-bold mb-5">Accelerate Your Ability to Connect With Customers</h4>
              <p className="text-base mb-16 font-normal">As the pandemic continues to put the world in constant flux, this is your chance to connect, network, and learn more about how programmable communications can position you to win, today and tomorrow.</p>
              <a href="/" className="text-black border-b-2 tracking-widest border-black text-sm font-semibold">LEARN MORE</a>
              </div>
          </div>
      </div>
    </div>
  );
}

export default UpComing;
