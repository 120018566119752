import React from "react";
import Header from "./Header";
import OnDemand from "./OnDemand";
import UpComing from "./UpComing";
import data from "./../../data";

function Index({location}) {
  return (
    <div>
      <Header />
    
      <UpComing />
      <OnDemand card3={data.card3} />
    </div>
  );
}

export default Index;
