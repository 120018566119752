import React from "react";

function Header() {
  return (
    <div>
      {" "}
      <div className="bg-events">
        <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
          <div className="grid grid-cols-1">
            <div>
              <h1 className=" font-medium text-white md:text-5xl text-4xl mb-4">
                Vonage Events
              </h1>
              <p className="md:text-xl text-lg font-normal text-white mb-16">
                Check out our upcoming and on demand events below.
              </p>
              <div className="flex flex-wrap md:justify-start justify-center">
                <a
                  href="/"
                  className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  Upcoming
                </a>
                <a
                  href="/"
                  className="border-white border-2 text-white px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  OnDemand
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
